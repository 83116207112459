<template>
  <div style="position: relative">
    <a-carousel
      v-if="totalItem >= slideToShow"
      :after-change="onSlideChange"
      :class="`slide-length-${slideToShow}`"
      :infinite="false"
      ref="slick"
    >
      <slot></slot>
    </a-carousel>
    <div v-else class="row" :class="`row-cols-${slideToShow}`">
      <slot></slot>
    </div>
    <div
      id="previous"
      v-if="page !== 1 && totalItem > 0"
      style="left: -25px; position: absolute; top: 50%"
    >
      <a-button type="link" style="padding: unset" :onClick="onPreviousVideo">
        <img
          src="../../assets/icons/arrow_down_icon.svg"
          alt="arrow-up"
          style="width: 25px; height: 25px; transform: rotate(90deg)"
        />
      </a-button>
    </div>
    <div
      id="next"
      v-if="(isFull === false || lastPage !== page) && totalItem >= size"
      style="right: -25px; position: absolute; top: 50%"
    >
      <a-button type="link" style="padding: unset" :onClick="onNextVideo">
        <div v-if="arrowLoading" class="loading-container">
          <LoadingOutlined />
        </div>
        <img
          v-else
          src="../../assets/icons/arrow_down_icon.svg"
          alt="arrow-up"
          style="width: 25px; height: 25px; transform: rotate(270deg)"
        />
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, onUpdated, reactive, ref, toRefs } from "vue";
import {
  LeftOutlined,
  RightOutlined,
  LoadingOutlined,
} from "@ant-design/icons-vue";
export default defineComponent({
  props: {
    slideToShow: {
      type: Number,
      required: false,
      default: 5,
    },
    totalItem: {
      type: Number,
    },
    onFetchData: {
      type: Function,
    },
    objectName: {
      type: String,
      required: true,
    },
    global_config_local_types: {
      type: String,
      required: true,
    },
  },
  components: {
    LeftOutlined,
    RightOutlined,
    LoadingOutlined,
  },
  setup(props) {
    const { onFetchData, totalItem, objectName, global_config_local_types } =
      props;
    const slick = ref(null);

    const state = reactive({
      arrowLoading: false,
      size: 5,
      page: 1,
      lastPage: 1,
      isFull: false,
    });

    const onSlideChange = (current) => {
      // console.log(current);
    };

    const onPreviousVideo = () => {
      if (state.page > 1) {
        slick.value.prev();
        state.page -= 1;
      }
    };

    const onNextVideo = async () => {
      if (state.page === state.lastPage) {
        state.lastPage += 1;
        state.arrowLoading = true;
        const res = await onFetchData({
          page: state.lastPage,
          size: state.size,
          objectName,
          global_config_local_types,
        });
        if (res.data.length < state.size) {
          state.isFull = true;
        }
        setTimeout(() => {
          state.page += 1;
          state.arrowLoading = false;
          slick.value.next();
        });
      } else {
        slick.value.next();
        state.page += 1;
      }
    };

    onUpdated(() => {
      // console.log("slideTeam :: ", {
      //   page: state.page,
      //   lastPage: state.lastPage,
      // });
    });

    return {
      // ...toRefs(props),
      ...toRefs(state),
      onSlideChange,
      onPreviousVideo,
      onNextVideo,
      slick,
      totalItem,
    };
  },
});
</script>
<style lang="less" scoped>
.sat-carousel {
  :deep(.slick-list) {
    overflow: hidden;
    margin: 0 -10px;
    .slick-slide {
      margin: 0 10px;
    }
  }
  &.slide-length- {
    &4 {
      :deep(.slick-list) {
        .slick-slide {
          width: 215px !important;
        }
      }
    }
    &5 {
      :deep(.slick-list) {
        .slick-slide {
          max-width: 234px;
        }
      }
    }
  }
  :deep(.slick-arrow.custom-slick-arrow) {
    z-index: 9;
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: #0f4c82;
    // background-color: rgba(31, 45, 61, 0.11);
    opacity: 0.3;
    transition: all 0.5s;
    &:before {
      display: none;
    }
    &:hover {
      opacity: 0.9;
    }
  }
}
:deep(.sat-carousel .slick-list .slick-slide) {
  pointer-events: unset;
}
</style>
